import '../ui-anchor/ui-anchor.lv.js';
import '../ui-annotation/ui-annotation.lv.js';
import '../ui-assets/ui-assets.lv.js';
import '../ui-autocomplete/ui-autocomplete.lv.js';
import '../ui-breadcrumbs/ui-breadcrumbs.lv.js';
import '../ui-calendar/ui-calendar.lv.js';
import '../ui-chip/ui-chip.lv.js';
import '../ui-comparison-cards/ui-comparison-cards.lv.js';
import '../ui-cookie-consent/ui-cookie-consent.lv.js';
import '../ui-countdown/ui-countdown.lv.js';
import '../ui-curtain/ui-curtain.lv.js';
import '../ui-datepicker/ui-datepicker.lv.js';
import '../ui-daterange/ui-daterange.lv.js';
import '../ui-dialogbubble/ui-dialogbubble.lv.js';
import '../ui-dropdown/ui-dropdown.lv.js';
import '../ui-feedback/ui-feedback.lv.js';
import '../ui-form/ui-form.lv.js';
import '../ui-message/ui-message.lv.js';
import '../ui-modal/ui-modal.lv.js';
import '../ui-monthpicker/ui-monthpicker.lv.js';
import '../ui-nav/ui-nav.lv.js';
import '../ui-navitem/ui-navitem.lv.js';
import '../ui-navtoggle/ui-navtoggle.lv.js';
import '../ui-navlist/ui-navlist.lv.js';
import '../ui-offer/ui-offer.lv.js';
import '../ui-pagination/ui-pagination.lv.js';
import '../ui-phonefield/ui-phonefield.lv.js';
import '../ui-rating/ui-rating.lv.js';
import '../ui-searchinput/ui-searchinput.lv.js';
import '../ui-signing/ui-signing.lv.js';
import '../ui-slider/ui-slider.lv.js';
import '../ui-slides/ui-slides.lv.js';
import '../ui-stepper/ui-stepper.lv.js';
import '../ui-tabs/ui-tabs.lv.js';
import '../ui-timerange/ui-timerange.lv.js';
import '../ui-table-cloneable/ui-table-cloneable.lv.js';
import '../ui-table-expandable/ui-table-expandable.lv.js';
import '../ui-table-sortable/ui-table-sortable.lv.js';
import '../ui-textcounter/ui-textcounter.lv.js';
import '../ui-upload/ui-upload.lv.js';
import '../ui-upload-item/ui-upload-item.lv.js';
import '../ui-video/ui-video.lv.js';
import '../ui-wizard/ui-wizard.lv.js';
import { Labels } from '../../shared-components/global/labels.js';

Labels.LANGUAGE = 'lv';

window.UI = { ...window.UI, Labels };
